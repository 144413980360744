<template>
  <div class="content">
    <PageHeader :title="$t('generic-str.menu.anti-fraud')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card card-tabs">
            <div class="card-header">
              <ul class="nav nav-tabs primary-tabs">
                <li class="nav-item nav-m-custom" role="presentation">
                  <a
                    href="javascript:void(0)"
                    class="nav-link active"
                    v-tab="'queued'"
                    data-toggle="tab"
                    aria-expanded="true"
                    >{{$t('generic-str.queue')}}</a
                  >
                </li>
                <li class="nav-item nav-m-custom" role="presentation">
                  <a
                    href="javascript:void(0)"
                    class="nav-link"
                    v-tab="'words'"
                    aria-expanded="true"
                    >{{$tc('generic-str.word', 2)}}</a
                  >
                </li>
                <li class="nav-item nav-m-custom" role="presentation">
                  <a
                    href="javascript:void(0)"
                    class="nav-link"
                    v-tab="'urls'"
                    aria-expanded="true"
                    >Urls</a
                  >
                </li>
                <li class="nav-item nav-m-custom" role="presentation">
                  <a
                    href="javascript:void(0)"
                    class="nav-link"
                    v-tab="'messages'"
                    aria-expanded="true"
                    >{{$tc('generic-str.message', 2)}}</a
                  >
                </li>
                <!-- <li class="nav-items ml-auto">
                  <button class="btn btn-success" @click="showModal" ref="btnShow">
                    {{$t('anti-fraud-component.register-word')}}
                  </button>
                </li> -->
              </ul>
            </div>
            <div class="card-body block-el">
              <div class="tab-content">
                <div class="tab-pane active" id="queued">
                  <queued ref="queued" @validated="fetchAll"/>
                </div>
                <div class="tab-pane" id="words">
                  <words />
                </div>
                <div class="tab-pane" id="urls">
                  <urls @validated="$refs.queued.fetch(1)" ref="urls"/>
                </div>
                <div class="tab-pane" id="messages">
                  <messages ref="messages"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Tab from '@/directives/Tab';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Queued from '@/components/rebranding/antifraud/Queued.vue';
import Words from '@/components/rebranding/antifraud/Words.vue';
import Urls from '@/components/rebranding/antifraud/Urls.vue';
import Messages from '@/components/rebranding/antifraud/Messages.vue';

export default {
  name: 'AdminAntifraud',
  components: {
    PageHeader,
    Queued,
    Words,
    Urls,
    Messages,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      fetched: false,
      tickets: [],
      messages: [],
      id: '',
      form: {
        sort: 'scheduled_at:desc',
        page: 1,
      },
      pages: 1,
      totalMessages: 0,
      exporting: false,
      isSending: false,
      opens: [],
      opensPercent: 0,
      answeredPercent: 0,
      answered: [],
    };
  },
  computed: {
    info() {
      return this.$store.state.account;
    },
  },
  methods: {
    fetchAll() {
      this.$refs.messages.fetch(1);
      this.$refs.urls.fetch(1);
    },
    /* showModal() {
      this.$root.$emit('bv::show::modal', 'word-modal', '#btnShow');
    }, */
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
td a i {
  font-size: 1.19rem;
}

/* Rebranding */

.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}
.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}
.nav.nav-tabs .nav-item .nav-link.active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
  color: var(--clr-light-2);
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.darkmode .nav-tabs .nav-link.active {
  background-color: var(--background-3);
}

.darkmode .nav.nav-tabs .nav-item .nav-link:hover,
.darkmode .nav.nav-tabs .nav-item .nav-link:hover i {
  color: #fff;
}
</style>
