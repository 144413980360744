<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6">
        <button class="btn btn-yup-purple" v-modal="{ target: 'word-modal' }">
          {{$t('anti-fraud-component.register-word')}}
        </button>
      </div>
      <div class="col-12 col-md-6">
        <form @submit.prevent="fetch(1)">
          <div class="form-group">
            <div class="input-group mb-3">
              <input
                v-model="form.search"
                type="text"
                :placeholder="`${$t('generic-str.search-by.default')}...`"
                aria-label=""
                aria-describedby="basic-addon2"
                class="form-control"
              />
              <div class="input-group-append">
                <button
                  type="submit"
                  class="btn btn-yup-purple btn-icon btn-search-id"
                >
                  <i class="fas fa-search text-white" style="color: #fff !important;"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <br />
        <TableComponent :fields="fields" :items="words" :busy="fetched">
          <template #cell(status)="slotProps">
            <BadgeComponent variant="success" v-if="slotProps.data == 'whitelisted'">{{$t('generic-str.status.lbl-released')}}</BadgeComponent>
            <BadgeComponent variant="danger" v-if="slotProps.data == 'blacklisted'">{{$t('generic-str.status.lbl-blocked-o')}}</BadgeComponent>
          </template>
          <template #cell(account)="slotProps">
            <span v-if="slotProps.item.account">{{ slotProps.item.name }}</span>
            <span v-else>{{$t('generic-str.all')}}</span>
          </template>
          <template #cell(created_at)="slotProps">
            {{ slotProps.data | date('DD/MM/YYYY HH:mm:ss') }}
          </template>
          <template #cell(actions)="slotProps">
            <button
              @click="destroy(slotProps.item.id)"
              class="btn btn-danger btn-remove"
            >
              <i class="icon dripicons-trash color-white" style="color: #fff !important;"></i>
            </button>
          </template>
        </TableComponent>
        <br />
        <pagination :totalPages="pages" v-model="currentPage" @change="fetch" />
      </div>
    </div>
    <word-modal @sent="fetch(1)" id="word-modal"></word-modal>
  </div>
</template>

<script>
import AntifraudService from '@/services/admin/antifraud.service';
import Pagination from '@/components/PaginationComponent.vue';
import WordModal from '@/components/rebranding/antifraud/WordModal.vue';
import Swal from 'sweetalert2';
import TableComponent from '@/components/TableComponent.vue';
import BadgeComponent from '@/components/ui/BadgeComponent.vue';

export default {
  name: 'AntifraudWords',
  components: {
    Pagination,
    WordModal,
    TableComponent,
    BadgeComponent,
  },
  data() {
    return {
      fields: [
        'created_at',
        'word',
        'status',
        'account',
        'actions',
      ],
      fetched: false,
      words: [],
      pages: 1,
      form: {
        search: '',
        page: 1,
      },
      currentPage: 1,
    };
  },
  created() {
    this.fetch(1);
  },
  /* mounted() {
    this.$root.$on('component1', () => {
      // your code goes here
      this.fetch(1);
    });
  }, */
  methods: {
    fetch(page) {
      this.form.page = page;
      this.fetched = true;
      this.currentPage = page;
      AntifraudService.getAntifraudWords(this.form).then(
        (response) => {
          this.fetched = false;
          this.words = response.data;
          this.pages = response.last_page;
          this.totalMessages = response.total;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    destroy(wordId) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          this.$t('warnings.anti-fraud.label-word'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetched = false;
          AntifraudService.deleteWord(wordId).then(
            () => {
              this.fetch(1);
              Swal.fire(`${this.$t('generic-str.deleted')}!`, `${this.$t('warnings.anti-fraud.deleted-word')}.`, 'success');
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
  },
};
</script>
