<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Nova Url</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="save" class="form-horizontal">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <div class="col-6">
                  <label class="control-label text-left">Url</label>
                  <input
                    v-model="form.url"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-6">
                  <label class="control-label text-left">{{$t('generic-str.type')}}</label>
                  <select
                    v-model="form.status"
                    type="text"
                    class="form-control"
                    required
                  >
                    <option value="whitelisted">Permitido</option>
                    <option value="blacklisted">{{$t('generic-str.status.lbl-blocked-o')}}</option>
                  </select>
                </div>
                <div class="col-6">
                  <label class="control-label text-left">{{$t('generic-str.customer')}}</label>
                  <select-input
                    label="name"
                    v-model="form.account_id"
                    :reduce="(field) => field.id"
                    :options="customers"
                    :clearable="true"
                    multiple
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-yup-purple-outline" @click="hide">
              {{$t('generic-str.cancel')}}
            </button>
            <button
              type="submit"
              class="btn btn-yup-purple"
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
            >
              {{$t('generic-str.save')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AntifraudService from '@/services/admin/antifraud.service';
import CustomerService from '@/services/customer.service';
import Modal from '@/mixins/Modal';
import SelectInput from '@/components/SelectInput.vue';

export default {
  name: 'UrlModal',
  components: {
    SelectInput,
  },
  mixins: [Modal],
  props: ['id'],
  data() {
    return {
      customers: [],
      form: {
        url: '',
        status: 'whitelisted',
      },
      isSending: false,
    };
  },
  created() {
    this.fetchCustomers();
  },
  methods: {
    fetchCustomers() {
      CustomerService.getCustomers().then(
        (customers) => {
          this.customers = customers;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    save() {
      this.isSending = true;
      AntifraudService.createUrl(this.form).then(
        () => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Palavra cadastrada',
            type: 'success',
          });
          this.$emit('sent');
          this.isSending = false;
          this.hide();
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.vti__dropdown-list {
  z-index: 1000 !important;
}
.vue-tel-input {
  border-color: #dfe7f3 !important;
  border: 1px solid rgba(120, 141, 180, 0.3) !important;
}
.loading-cep {
  right: 40px;
  top: 40px;
  position: absolute;
  padding: initial;
}
</style>
