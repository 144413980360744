<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6">
        <button class="btn btn-yup-purple" v-modal="{ target: 'url-modal' }">
          {{$t('anti-fraud-component.register-url')}}
        </button>
      </div>
      <div class="col-12 col-md-6">
        <form @submit.prevent="fetch(1)">
          <div class="form-group">
            <div class="input-group mb-3">
              <input
                v-model="form.search"
                type="text"
                :placeholder="`${$t('generic-str.search-by.default')}...`"
                aria-label=""
                aria-describedby="basic-addon2"
                class="form-control"
              />
              <div class="input-group-append">
                <button
                  type="submit"
                  class="btn btn-yup-purple btn-icon btn-search-id"
                >
                  <i class="fas fa-search text-white"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div v-if="fetched && urls.length" class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>{{$t('generic-str.date')}}</th>
                <th>{{$tc('generic-str.word', 1)}}</th>
                <th>Status</th>
                <th>{{$t('generic-str.customer')}}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="url in urls" :key="url.id">
                <td role="cell" :data-label="$t('generic-str.date')">
                  {{ url.created_at | date('DD/MM/YYYY HH:mm:ss') }}
                </td>
                <td role="cell" :data-label="$tc('generic-str.word', 1)" class="max-200">
                  {{ url.url }}
                </td>
                <td data-label="Status">
                  <span
                    v-if="url.status == 'whitelisted'"
                    class="badge badge-success"
                  >
                    {{$t('generic-str.status.lbl-released')}}
                  </span>
                  <span
                    v-else-if="url.status == 'blacklisted'"
                    class="badge badge-danger"
                  >
                    {{$t('generic-str.status.lbl-blocked-o')}}
                  </span>
                </td>
                <td role="cell" :data-label="$t('generic-str.customer')">
                  <span v-if="url.account">{{ url.account.name }}</span>
                  <span v-else>{{$t('generic-str.all')}}</span>
                </td>
                <td :data-label="$t('generic-str.action')">
                  <button
                    @click="destroy(url.id)"
                    class="btn btn-yup-purple-outline btn-remove"
                  >
                    <i class="icon dripicons-trash text-yup"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="fetched" class="text-center table-placeholder">
          <LottieAnimNoData />
          <h5 class="card-title m-t-20">{{$t('warnings.anti-fraud.url')}}</h5>
        </div>
        <div class="loading min-h-300" v-else>
          <LoadingAnim />
        </div>
        <br />
        <pagination :lastPage="pages" @change="fetch" />
      </div>
    </div>
    <url-modal @sent="validated" id="url-modal"></url-modal>
  </div>
</template>

<script>
import AntifraudService from '@/services/admin/antifraud.service';
import Pagination from '@/components/Pagination.vue';
import UrlModal from '@/components/rebranding/antifraud/UrlModal.vue';
import Swal from 'sweetalert2';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';

export default {
  name: 'AntifraudUrls',
  components: {
    Pagination,
    UrlModal,
    LottieAnimNoData,
    LoadingAnim,
  },
  data() {
    return {
      fetched: false,
      urls: [],
      pages: 1,
      form: {
        search: '',
        page: 1,
      },
    };
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      AntifraudService.getUrls(this.form).then(
        (response) => {
          this.fetched = true;
          this.urls = response.data;
          this.pages = response.last_page;
          this.totalMessages = response.total;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    validated() {
      this.fetch(1);
      this.$emit('validated');
    },
    destroy(urlId) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('warnings.anti-fraud.label'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetched = false;
          AntifraudService.deleteUrl(urlId).then(
            () => {
              this.fetch(1);
              Swal.fire(
                `${this.$t('generic-str.deleted')}!`,
                `${this.$t('warnings.anti-fraud.deleted')}.`,
                'success',
              );
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.table-responsive {
  width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    color: #6F6F6F;
    margin-bottom: 1rem;
    table-layout: fixed;

    thead {
      th {
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        padding: 12px;
        white-space: nowrap;
      }
    }

    tbody {
      tr {
        td {
          font-size: 14px;
          font-weight: 400;
          padding: 12px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .clickable {
      cursor: pointer;
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}

.table-responsive table tbody tr {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border: 1px solid #E5E5E5;
  border-radius: 6px;
}

.table-responsive table tbody {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  text-align: center;
}

.table-responsive table thead tr {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 3px solid rgba(107, 35, 220, 0.2);
  margin-bottom: 1rem;
  align-items: center;
  text-align: center;
}

.table-responsive table tbody tr td {
  width: 25%;
  text-align: center;
}

.table-responsive table thead tr th {
  width: 25%;
  text-align: center;
}

table tbody td {
  border: none;
}

@media (max-width: 700px) {
  .table-responsive table tbody tr {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-radius: 6px;
  }

  .table-responsive table tbody tr td {
    text-align: end;
    position: relative;
    padding-left: 50%;
    width: 100%;
    height: 4rem;
    box-sizing: border-box;
    &:before {
      content: attr(data-label);
      position: absolute;
      left: 16px;
      width: calc(50% - 16px);
      text-align: left;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  table {
    thead {
      display: none;
    }
  }
}
</style>
